import React, { useEffect } from "react";
import {  Section, SportContainer, MainText,SubtextContainer, Subtext, SpecialText, InnerContentContainer, AllTextContainer, MainTextContainer, TitleContainer, SportLandingTitle, SportName, RegisterButtonContainer, RegisterButton} from "../../../App.styled";
import { Link } from 'react-router-dom';


function YouthCamps() {



    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      const registerLink = 'https://docs.google.com/forms/d/1aLtRn80NDHJpaAnAeWKOcQ7fRv18Paj-AWK9A8jNbo4/viewform?edit_requested=true';

    return (

        <Section>
        <SportContainer>
            <InnerContentContainer >

                <TitleContainer>
                    <SportLandingTitle>Youth Development Camp 2025!
                    </SportLandingTitle>
                    <SportName>Development Camp</SportName>
                </TitleContainer>

                <RegisterButtonContainer>
                    <RegisterButton disabled target="_blank" rel="noopener noreferrer" as={Link} to={registerLink}>Player Registration</RegisterButton>
                </RegisterButtonContainer>

                <AllTextContainer>
                    <MainTextContainer>

                    
                        <MainText>
                        Join us for an unforgettable experience designed for ages 6-12, promising skill enhancement and loads of fun. 
                        Camp will be hosted this summer, dates are TBD.  Our experienced coaches, with backgrounds in high school and 
                        college sports, are committed to providing a nurturing environment where every participant becomes an integral 
                        part of our extended sports family.
                        <br></br>
                        <br></br>
                        At <SpecialText>Ohana Sports</SpecialText>, you're not just a player or a spectator; you're part of something truly special. 
                        This spring, we invite you to share your passion for sports and be a part of our community where learning, growth, and play go 
                        hand in hand.
                        <br></br>
                        <br></br>
                        Our 2025 Youth Camp offers introductory sessions where kids will dive into the fundamentals of various sports, 
                        including basketball, non-contact flag football, golf, kickball and volleyball. Throughout the camp, participants
                         will not only develop their skills but also foster essential team-building abilities such as collaboration, 
                         communication, and sportsmanship.

                        <br></br>
                        <br></br>

                        We ensure a rewarding and enjoyable experience for young athletes. Come celebrate the unifying power of sports 
                        with us and become a part of a community that cares. Let's make this spring break one to remember!
                        
                        </MainText>

                    </MainTextContainer>
                    <SubtextContainer style={{marginTop: '40px'}}>
                            <Subtext style={{textAlign: 'right'}}>
                            For any inquiries or assistance, feel free to reach out to us via Instgram, or Email at the bottom of every page. 
                            We're here to ensure you have the best experience with Ohana Sports. 
                            Mahalo for Supporting <SpecialText style={{color: '#34B3D1'}}>Ohana Sports</SpecialText>!
                            </Subtext>
                            <Subtext>
                            We're committed to providing a comprehensive and engaging experience for all basketball fans.
                             Keep this page bookmarked for the latest schedules, scores, and updates.
                            </Subtext>
                        </SubtextContainer>
                </AllTextContainer>
            </InnerContentContainer>
        </SportContainer>
    </Section>

    );
}

export default YouthCamps;

