import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colorMap } from '../../../App.styled';

//Color Key:
// Logo blue: #34b3d1
// Logo Mustard Yellow: #ffd369
// background grey: #f6f6f6  
// dark blue/black #333
// dark blue #282c34
// score grey: #edeef0

export const MenuBar = styled.div`
  display: flex; 
  align-items: center;
  background-color: ${colorMap.light.text};
  font-family: 'Futura', sans-serif; 
  height: 60px;
  position: sticky; 
  top: 0;
  z-index: 5;
  @media(max-width: 768px) {
    display: none;
  }
`;
export const MenuOptionsContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center; /* Center the menu options container */
  flex: 1; /* Ensures the menu options container takes up the remaining space */
  gap: 64px;

  @media (max-width: 1040px) {
    gap: 36px;
  }
`;


export const MenuLogo = styled.img`
  width: 78px;
  height: 60px;
  margin-right: auto; 
  transform: scale(1.5);
  position: absolute;
  left: 60px;
  bottom: 4px;
  @media (max-width: 1040px) {
    display: none;

  }
`;



//#1f1f1f
export const MenuItem = styled(Link)`
  color: ${colorMap.light.accent}; 
  letter-spacing: 4px;
  text-decoration: none; 
  cursor: pointer;
  padding: 10px 15px; 
  display: inline-flex; 
  flex-wrap: nowrap;
  font-size: 16px;
  position: relative; 
  transition: .2s; 

  &:hover {
    background-color: ${colorMap.light.accent}; 
    color: ${colorMap.light.primary};
    border-radius: 10px;

    .dropdown-content {
      display: flex; 
      flex-direction: column; 
      flex-wrap: nowrap; 
      left: 15%;  
      top: 100%; 
      z-index: -1;
    }
  }
`;

export const DropdownContent = styled.div`
  display: none; 
  letter-spacing: 2px;
  position: absolute; 
  color: ${colorMap.light.accent}; 
  min-width: 8rem;
  text-align: left;
  background-color: ${colorMap.light.text}; 
  font-size: 1rem;
  z-index: 1;

  a {
    color: ${colorMap.light.accent}; 
    padding: .4em .5em; 
    text-decoration: none;
    text-align: center;
    &:hover { 
      background-color: ${colorMap.light.accent}; 
      color: ${colorMap.light.primary}; 
    }
  }
  
  @media (max-width: 768px) {
    width: 100%; 
    top: 100%;
  }
`;

