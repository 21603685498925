// HomePage.jsx
import React, { useContext} from "react";
import {  MainContent} from '../../../App.styled'
import { HomeDataTextBottom, HomeDataTitle, HomeDataBottomBanner, HomeDataBottomTextContainer,  HomeDataButton, HomeContainer, HomeDataContainer, HomeDataBanner, HomeLogo} from './Home.styled';
import ohana from "../../logo/logo.svg"

import homeData from "./HomeStories";

import { ThemeContext } from "../../../ColorContext";

function Home() {
    const {theme, toggleTheme} = useContext(ThemeContext)

    console.log(theme, toggleTheme);

    return (
        <MainContent>
            <HomeContainer>

            <HomeLogo src={ohana} />
            {homeData.map(story => {
                return (


                    <HomeDataContainer key={story.id} bgImage={story.picture} topImage={story.topImage}>
                    <HomeDataBanner>
                        <HomeDataTitle>{story.subtitle}</HomeDataTitle>
                    </HomeDataBanner>
                        
                    <HomeDataBottomBanner>
                        <HomeDataBottomTextContainer>
                        <HomeDataTextBottom>
                            {story.description}
                        </HomeDataTextBottom>
                 
                        </HomeDataBottomTextContainer>
                        
                        <HomeDataButton to={story.learnMoreRoute}>{story.moreInfo}</HomeDataButton>

                    </HomeDataBottomBanner>
                        
                    </HomeDataContainer>
                )
            })}
    </HomeContainer>
        </MainContent>
    );
}

export default Home; 
