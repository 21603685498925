// HomePage.styled.jsx
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colorMap } from '../../../App.styled';


//Color Key:
// Logo blue: #34b3d1
// Logo Mustard Yellow: #ffd369
// background grey: #f6f6f6
// dark blue/black #333
// dark blue #282c34
// score grey: #edeef0
// logo black: #

export const HomeContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;

`
export const HomeDataContainer = styled.div`
display: flex;
flex-direction: column;
width: 1085px;
height: 524px;
border-radius: 15px;
background: ${({ bgImage, topImage }) => 
topImage 
  ? `url(${bgImage}) lightgray no-repeat center top` 
  : `url(${bgImage}) lightgray no-repeat center center`
};
background-size: cover; 
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 5px 0px rgba(0, 0, 0, 0.30);
justify-content: space-between;
margin-bottom: 15vh;

@media(max-width: 1200px) {
  width: 868px;
  height: 412px;
  margin-bottom: 12vh;
}

@media(max-width: 948px) {
  width: 612px;
  height: 372px;
  margin-bottom: 10vh;
}

@media(max-width: 712px) {
  width: 412px;
  height: 312px;
}
  @media(max-width: 412px) {
  width: 350px;
  height: 272px;
}
`;


export const HomeDataBanner = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 1085px;
height: 100px;
flex-shrink: 0;
border-radius: 15px 15px 0px 0px;
background: rgba(0, 0, 0, 0.80);
@media(max-width: 1200px) {
  width: 868px;
  height: 80px;
}
@media(max-width: 948px) {
  width: 612px;
  height: 66px;
}
@media(max-width: 712px) {
  width: 412px;
  height: 54px;

}
    @media(max-width: 412px) {
  width: 350px;
  height: 50px;
}
`
export const HomeDataTitle = styled.h2`
color: ${colorMap.light.accent};

text-align: center;
text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin: 0;
@media(max-width: 1200px) {
  font-size: 28px;

}
@media(max-width: 948px) {
  font-size: 24px;

}
@media(max-width: 712px) {
  font-size: 20px;

}
 

`

export const HomeDataBottomBanner = styled.div`
display: flex;
box-sizing: border-box;
width: 1085px;
height: 100px;
padding: 20px 12px;
justify-content: space-between;
align-items: center;
flex-shrink: 0;
border-radius: 0px 0px 15px 15px;
background: rgba(255, 255, 255, 0.70);
align-self: start;
@media(max-width: 1200px) {
  width: 868px;
  height: 92px;
}
@media(max-width: 948px) {
  width: 612px;
  height: 84px;
}
@media(max-width: 712px) {
  width: 412px;
    height: 72px;
    padding: 16px 12px;


}
      @media(max-width: 412px) {
  width: 350px;
  height: 52px;
  padding: 10px 8px;

}
`

export const HomeDataTextBottom = styled.p`
color: var(--Black, #000);
margin: 0;
font-family: Nokora;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.16px;
@media(max-width: 1200px) {
  font-size: 14px;
  font-weight: 500;


}
@media(max-width: 948px) {
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
`

export const AboutContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  border-bottom: 2px solid #34b3d1;
  box-sizing: border-box;
  margin: 1rem 0;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }
  
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    padding: 2rem;
    
    &:nth-child(even) {
      & > :first-child {
        grid-column: 2 / 3; /* Moves the first child to the second column for even containers */
      }
      & > :last-child {
        grid-column: 1 / 2; /* Moves the last child to the first column for even containers */
      }
    }
  }
`;


export const AboutTextContainer = styled.div`
  // Mobile-first styles
  max-width: 100%;
  padding: 1rem 2rem;
  height: auto;
  order: 3; 

  @media screen and (min-width: 768px) {
    min-width: 80%
  }
  @media screen and (min-width: 1070px) {
    padding: 0 2rem; 
  }

`;



export const AboutText = styled.p`
  // Mobile-first styles
  color: ${colorMap.light.text};
  line-height: 1.2;
  font-size: 20px; 
  text-align: left;
  margin: auto;
  width: 95%; // Use full width on mobile
  padding: 1rem 0; 

  @media screen and (min-width: 768px) {
    line-height: 1;
    font-size: 20px; // Start with the smallest size
    overflow-wrap: break-word;
  }

  @media screen and (min-width: 1070px) {
    font-size: 20px; 
    line-height: 1.4;
    width: 100%; 
  }
`;

export const AboutPictureContainer = styled.div`
  height: auto; 
  position: relative;
  box-sizing: border-box;
  padding-bottom: 2.5rem;
  order: 2; // Picture second
  width: 100%; 
  margin-bottom: 1rem; 
  @media screen and (min-width: 768px) { 
    width: 100%;
    order: 1;
    display: block; 
    margin-bottom: 1rem;
    grid-row: 1 / span 2;
  }

`;

export const Pic = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 50%; 
  object-fit: cover; 


  @media screen and (min-width: 768px) {
    height: 400px;
    width: 400px;
    border-radius: 10px; 
    height: auto; 

  }
  @media screen and (min-width: 1070px) {
    height: 550px;
    width: 550px;
    border-radius: 10px;
    height: auto;
  }
`;

export const HighlightTextBold = styled.p`
  position: relative;
  z-index: 1;
  color: ${colorMap.light.secondary};
  text-align: center;
  margin: 5px;
  font-size: 20px;
  font-weight: bold;
`;


export const HomeDataButton = styled(Link)`
  width: 158px;
  height: 46px;
  margin: 0; 
  color: ${colorMap.light.background};
  background-color: ${colorMap.light.primary}; 
  color: white;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  white-space: nowrap; 
  max-width: 100%; 
  box-sizing: border-box;
  font-size: 16px; 
  font-weight: bold;
  letter-spacing: .1rem;  
  cursor: pointer; 
  align-self: center;
  line-height: 46px; /* Ensure vertical centering of text */
  /* Resetting default link styles */
  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
    
  }
  &:hover {
    background-color:  ${colorMap.light.accent}; 
  }
  @media screen and (max-width: 1070px) {
    font-size: 14px;
    font-weight: 600;


  }
`;

export const HomeDataBottomTextContainer = styled.div`
  display: flex; 
  flex-direction: column;
  width: 100%;
  height: 100%;
  width: 70%;
`

export const HighlightBoxButton = styled(Link)`
  width: fit-content;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${colorMap.light.accent};
  padding: 10px;
  margin: 0 auto;
  border-radius: 0px 20px 0px 20px;
  text-wrap: nowrap;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: block;
  color: ${colorMap.light.text};
  
  text-decoration: none;
  transition: color 0.2s;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 0; 
    width: 0%;
    background-color: ${colorMap.light.text};
    transition: width 0.3s ease; 
    border-radius: 0px 20px 0px 20px;
  }

  &:hover {
    &::before {
      width: 100%;
    }

    & ${HighlightTextBold} {
      color: ${colorMap.light.accent};
    }
  }
`;




export const Highlight = styled.span`
color: ${colorMap.light.primary};
font-weight: bold;
`;


export const Bold = styled.span`
color: ${colorMap.light.text};
font-weight: bold;
`


export const HomeLogo = styled.img`
width: 625px;
height: 355px;

@media(max-width: 948px) {
  width: 612px;
  height: 364px;
}
@media(max-width: 712px) {
  width: 312px;
  height: 272px;
}
`
