import React from "react";
import {  Section, SportContainer, MainText,SubtextContainer, Subtext, SpecialText, InnerContentContainer, AllTextContainer, MainTextContainer, TitleContainer, SportLandingTitle, SportName, RegisterButtonContainer, RegisterButton} from "../../../App.styled";
import { Link } from "react-router-dom";

function Kickball() {
   


    return (
      
        <Section style={{minHeight: '50vh'}}>
        <SportContainer>
            <InnerContentContainer style={{minHeight: '0'}}>

                <TitleContainer>
                    <SportLandingTitle>Coming Soon, Kickball (2025)</SportLandingTitle>
                    <SportName>Kickball</SportName>
                </TitleContainer>

                <RegisterButtonContainer>
                    <RegisterButton disabled  as={Link} >Player Registration</RegisterButton>
                    <RegisterButton disabled target="_blank" rel="noopener noreferrer" as={Link} >Team Registration</RegisterButton>
                </RegisterButtonContainer>

                <AllTextContainer>
                    <MainTextContainer>

                    
                        <MainText>
                        Who wants to play some kickball? <SpecialText>Co-ed Kickball is coming to Ohana Sports!</SpecialText> Check in for regular updates. 
                        Keep this page bookmarked for the latest schedules, scores, and updates. For any inquiries or assistance, 
                        feel free to Mahalo for Supporting Ohana Sports!
                        </MainText>

                    </MainTextContainer>
                   
                </AllTextContainer>
                <SubtextContainer>
                        <Subtext style={{textAlign: 'right'}}>
                        For any inquiries or assistance, reach out to us. 
                        We're here to ensure you have the best experience with Ohana Sports. 
                        Mahalo for Supporting <SpecialText style={{color: '#34B3D1'}}>Ohana Sports</SpecialText>!
                        </Subtext>
                        <Subtext>
                        We're committed to providing a comprehensive and engaging experience for all basketball fans.
                         Keep this page bookmarked for the latest schedules, scores, and updates.
                        </Subtext>
                    </SubtextContainer>
       
            </InnerContentContainer>
        </SportContainer>
    </Section>
       
    );
}

export default Kickball;




// <Title>Kickball Coming Soon!</Title>

// <ButtonContainer>
//     <RegisterButton as={Link} to={'https://docs.google.com/forms/d/e/1FAIpQLSeEU9DVa6hk8I8YIrNhzapmPNLDQa_EU1WXBoiKWAF9s0sTdQ/viewform'}>
//         <HighlightTextBold>Team Registration</HighlightTextBold>
//     </RegisterButton>
//     <RegisterButton as={Link} to={'https://docs.google.com/forms/d/e/1FAIpQLSel3Cc7HZqXCrhSh1atU2bwaRCcyl-DmZLw7utPe1EyS6FpZg/viewform'}>
//         <HighlightTextBold>Player Registration</HighlightTextBold>
//     </RegisterButton>
// </ButtonContainer>

// <Text>
//     Who wants to play some <Highlight>kickball</Highlight>?
//     Co-ed Kickball is coming to Ohana Sports! 
// </Text>

// <Subtitle>Stay Tuned!</Subtitle>

// <Text>
//     Check in for regular updates. 
//     We're constantly updating our site with the latest information and features to enhance your experience.
//     Don't strike out on these updates!
// </Text>

// <Text>
//     We're committed to providing a comprehensive and engaging experience for all Kickball fans.
//     Keep this page bookmarked for the latest schedules, scores, and updates.
// </Text>

// <Text>
//     For any inquiries or assistance, feel free to reach out to us. We're here to ensure you have the best experience with Ohana Sports.
// </Text>

// <Text>
//     Mahalo for Supporting Ohana Sports!
// </Text>