import teamwork from "../../logo/teamwork.svg"
import winning from "../../logo/winning.svg"
import football from "../../logo/football.svg"
import ohana from "../../logo/logowhite.svg"
import basketball from "../../logo/basketball.svg"





export const aboutData = [
    {
        title: "Our Story",
        text: `At Ohana Sports, our journey began with a simple vision: to create a sports community 
        where everyone is welcome, and every game is an opportunity to connect. Founded in the 
        heart of Kirkland, we've grown from a small group of sports enthusiasts into a thriving 
        family of athletes, volunteers, and supporters, all united by a love for the game and a 
        commitment to inclusivity.`,
        svg: ohana,
    },
    {
        title: "Our Mission",
        text: `Our mission is to provide engaging, enjoyable, and inclusive sporting experiences that do 
        more than just bring people together—they create a sense of belonging and foster genuine 
        community spirit. We're dedicated to promoting health, wellness, and personal development 
        through every pass, play, and point scored.`,
        svg: teamwork,

    },
    {
        title: "Serving the Eastside!",
        text: `Our impact extends beyond the field and into the heart of the Kirkland community. Through 
        partnerships, events, and volunteer initiatives, we strive to make recreational fun accessible to
        all, regardless of age or skill level. Whether you're a seasoned athlete or a newcomer to the world 
        of sports, there's a place for you in the Ohana family.`, 
        svg: winning,

    },
    {
        title: "Join the Family!",
        text: `As we continue to grow and evolve, our doors are always open to new members, volunteers, and partners 
        who share our passion for sports and community. Explore our programs, get involved, and let's make a 
        difference together. Welcome to Ohana Sports—where every game is a chance to belong, connect, and thrive.`, 
        svg: football,
    },
    {
        title: "Thank You!",
        text: `If you have any questions or need assistance in the meantime, please don't hesitate to reach out to us.
        We're here to help! Mahalo for Supporting Ohana Sports!`,
        svg: basketball
    }
];
