import React from "react";
import {StoryCard,  InnerAboutText} from "./About.styled"


function InfoContainer ({ text }) {


    return (
        <StoryCard>
            <InnerAboutText>
            {text}
            </InnerAboutText>

        </StoryCard>
    )
}

export default InfoContainer;