import React from "react";
import { StyledMenu } from "./Menu.styled";

// Define TypeScript interface for props


const Menu = ({ isOpen }) => {
    return (
        <StyledMenu open={isOpen}>
            <br />
            <a href="/">Home</a>
            <a href="/About">About</a>
            <a href="/Basketball">Basketball</a>
            <a href="/FlagFootball">Flag Football</a>
            <a href="/Kickball">Kickball</a>
            <a href="/youthCamps">Youth Camp</a>

        </StyledMenu>
    );
};

export default Menu;
