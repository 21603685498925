import React from 'react';
import Standings from './Standings';
import Schedule from './Schedule';
import {  SportContainer, TitleContainer, SportLandingTitle, SportName,  Section } from '../../../App.styled';



const League = () => {
  return (
    <Section>
      <SportContainer>
        <TitleContainer>
          <SportLandingTitle style={{ marginBottom: '2rem' }}> Winter 24' Basketball </SportLandingTitle>
        </TitleContainer>
      <SportName >Standings</SportName>
      <Standings  />
      <SportName style={{ marginTop: '2rem' }}>Schedule</SportName>
      <Schedule />
      </SportContainer>
    </Section>
  );
};

export default League;