import React from "react";
import { Section } from "../../../App.styled";
import InfoContainer from "./InfoContainer";
import { StoriesContainer, SVGImage, StoryTitle, TextContainer, ImageContainer } from "./About.styled";
import { aboutData } from "./aboutInfo";

function About() {
    return (
<Section style={{ marginTop: '64px' }}>
            {aboutData && aboutData.map((story, index) => {
                return (
                    <StoriesContainer key={index}>
                        {index % 2 === 0 ? (
                            <>  
                            <TextContainer>
                                <StoryTitle>{story.title}</StoryTitle>
                                <InfoContainer  text={story.text} />
                            </TextContainer>
                            <ImageContainer>
                                <SVGImage alt="corresponding image" src={story.svg} />
                            </ImageContainer>
                            
                            </>
                        ) : (
                            <>
                                <SVGImage alt="corresponding image" src={story.svg} />
                                <TextContainer>
                                <StoryTitle>{story.title}</StoryTitle>

                                <InfoContainer text={story.text} />
                            </TextContainer>
                            </>
                        )}
                    </StoriesContainer>
                );
            })}
        </Section>
    );
}

export default About;
