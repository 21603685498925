import React from "react";
import {  DateHeader, MatchupRow, TeamName, Versus, GameTime, TeamScore, LeftScore, RightScore } from "../League/Styles/Schedule.styled";
import {TeamBoxScoreSchedule} from "./Team.styled"
function Week ({week, weekIndex, teamId}) {
    return (
        <TeamBoxScoreSchedule key={weekIndex}>
                    <DateHeader>{week.week}</DateHeader>
                    {week.games.filter(game => game.team1 === teamId || game.team2 === teamId)
                        .map((filteredGame, gameIndex) => (
                            <MatchupRow key={gameIndex}>
                                <TeamName>
                                    {filteredGame.team1}
                                </TeamName>
                                <Versus>
                                    vs
                                </Versus>
                                <TeamName>
                                    {filteredGame.team2}
                                </TeamName>
                                <TeamScore>
                                    <LeftScore>
                                        {filteredGame.score1}
                                    </LeftScore>
                                    <RightScore>
                                        {filteredGame.score2}
                                    </RightScore>
                                </TeamScore>
                                <GameTime> at {filteredGame.time}</GameTime>
                            </MatchupRow>
                        ))}
                </TeamBoxScoreSchedule>
    )
}

export default Week;