import styled from 'styled-components';
import { colorMap } from '../../../../App.styled';
const goldenRatio = 1.61803398875;

export const ScheduleContainer = styled.div`
  font-family: 'Arial', sans-serif;
  max-width: 768px;
  border: 1px solid ${colorMap.light.primary};
  margin: 10px auto;
  background: white;
  border-radius: 4px;
  overflow: hidden; 
`;


export const DateHeader = styled.div`
background-color: ${props => props.isOdd ? '#f6f6f6' : 'white'};
  color: ${colorMap.light.primary};
  font-size: 20px;
  text-align: Left;
  font-weight: bold;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
  padding-top: 10px;
  padding: 10px 20px;
`;

export const MatchupTable = styled.div`
  width: 100%;
  

`;

export const MatchupRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr; 
  grid-template-rows: auto auto; 
  background-color: ${props => props.index % 2 === 0 ? '#f6f6f6' : 'white'};

  &:last-child {
    border-bottom: none;
  }
`;

export const TeamName = styled.span`

  font-weight: bold;
  color: ${colorMap.light.text};
  font-size: 1rem;
  text-align: center;
  @media screen and (min-width: 768px) { 
    font-size: 1.5rem;
  }
  padding: 10px;
`;

export const Versus = styled.span`
  grid-row: 1;
  grid-column: 2;
  align-self: center;
  justify-self: center;
  color: ${colorMap.light.text};
  font-size: 10px ;
  padding: 0px 30px; 
`;

export const GameTime = styled.span`
  grid-row: 2;
  grid-column: 1 / -1; 
  text-align: center;
  color: ${colorMap.light.text};
  font-size: calc(20px / ${goldenRatio});

`;

export const GameScore = styled.span`
  grid-row: 2; 
  color: ${colorMap.light.text};
  font-size: 20px;
  text-align: center;
`;

export const TeamScore = styled.div`
  display: contents; 
`;

export const LeftScore = styled(GameScore)`
  grid-column: 1; 
`;

export const RightScore = styled(GameScore)`
  grid-column: 3; 
`;