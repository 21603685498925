import React, {useEffect} from "react";
import {  Section, SportContainer, MainText,SubtextContainer, Subtext, SpecialText, InnerContentContainer, AllTextContainer, MainTextContainer, TitleContainer, SportLandingTitle, SportName, RegisterButtonContainer, RegisterButton} from "../../../App.styled";
import { InformationButton, LinkText} from "./Basketball.styled"
import { Link } from 'react-router-dom';
// import vector from "../../logo/textbarVector.svg"




function Basketball() {
    const year = new Date().getFullYear();
    const mapsUrl = `https://maps.app.goo.gl/Cn2CmV5q6g4oaCMn8`;

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    

    return (
      
        <Section>
            <SportContainer>
                <InnerContentContainer>

                    <TitleContainer>
                        <SportLandingTitle>Basketball League, {year}</SportLandingTitle>
                        <SportName>Basketball</SportName>
                    </TitleContainer>

                    <RegisterButtonContainer>
                        <RegisterButton target="_blank" rel="noopener noreferrer" as={Link} to={'https://docs.google.com/forms/d/e/1FAIpQLSe6yO44tnJUaLahekCRFGx0CzsWcHIRNYYkyB7LOSD7K3_usQ/viewform'}>Team Registration</RegisterButton>
                    </RegisterButtonContainer>

                    <AllTextContainer>
                        <MainTextContainer>

                        
                            <MainText>
                            Ohana Sports is excited to announce a meaningful <SpecialText>partnership with EBC</SpecialText>, 
                            where EBC will serve as the host venue for our basketball league games. 
                            Aligned with Ohana Sports' values, EBC's slogan, <i>"One Gym, One Family,"</i> 
                            perfectly encapsulates the spirit of <SpecialText>unity and community </SpecialText>that both organizations are committed to fostering. 
                                <br></br>
                                <br></br>
                                This collaboration promises to enhance the overall experience for our teams and participants, creating a dynamic and 
                                inclusive environment for everyone involved. Together, <SpecialText style={{color: '#34B3D1'}}>Ohana Sports</SpecialText> and <SpecialText> EBC</SpecialText> look forward to cultivating a sense of 
                                family and camaraderie within the basketball community. 
                            </MainText>

                        </MainTextContainer>
                        <SubtextContainer>
                            <Subtext >
                            For any inquiries or assistance, reach out to us. 
                            We're here to ensure you have the best experience with Ohana Sports. 
                            Mahalo for Supporting <SpecialText style={{color: '#34B3D1'}}>Ohana Sports</SpecialText>!
                            </Subtext>
                            <Subtext>
                            We're committed to providing a comprehensive and engaging experience for all basketball fans.
                             Keep this page bookmarked for the latest schedules, scores, and updates.
                            </Subtext>
                        </SubtextContainer>
                    </AllTextContainer>
                    <RegisterButtonContainer>
                        <InformationButton > <LinkText to="/Basketball/Schedule" >Schedule</LinkText></InformationButton>
                        <InformationButton > <LinkText target="_blank" to={mapsUrl}>Directions</LinkText></InformationButton>
                    </RegisterButtonContainer>
                </InnerContentContainer>
            </SportContainer>
        </Section>
       
    );
}

export default Basketball;

