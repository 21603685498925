import styled from 'styled-components';
import { colorMap } from '../../../../App.styled';

export const StandingsTable = styled.table`
  border-collapse: collapse;
  margin-top: 20px;
  margin: 0 auto;
  width: 500px;
  max-width: 768px;
  @media (max-width: 768px) {
    width: 350px;
  }
      @media (max-width: 612px) {
    width: 300px;
  }
`;
export const Title = styled.h1`
  color: ${colorMap.light.secondary};
  font-size: 1.6rem;
  margin-bottom: 10px;
`;
export const StandingsHeader = styled.th`
  background-color: ${colorMap.light.primary}; // Using color from your schedule
  color: ${colorMap.light.text};
  font-weight: bold;
  font-size: 14px;
  padding: 10px;
  
`;


export const StandingsRow = styled.tr`
  &:nth-child(even) {
    background-color: ${colorMap.light.background};
    font-size: 14px;
    StandingsPage.styled  
  }
`;

export const StandingsCell = styled.td`
  padding: 10px;
  border: 1px solid ${colorMap.light.secondaryBackground}; // Light gray border for cells
  font-size: 14px;
`;

export const TeamCell = styled(StandingsCell)`
  color: ${colorMap.light.text}; // Dark text for better readability
  font-size: 14px;
  font-weight: bold;
`;

export const StatCell = styled(StandingsCell)`
  text-align: center;
  font-size: 14px;
`;