import React, { useState, useEffect } from 'react';
import { ScheduleContainer, DateHeader, MatchupRow, GameTime, Versus, TeamScore, LeftScore, RightScore, TeamName } from './Styles/Schedule.styled';
import jsonData from '../Basketball/winter24.json';


const Schedule = () => {
    const [seasonSchedule, setSeasonScheduleData] = useState([]);

    useEffect(() => {
        setSeasonScheduleData(jsonData[0].Schedule);
    }, []);

    return (
        <>
        {seasonSchedule.map((week, weekIndex) => (

        <ScheduleContainer key={week.week}>           
                    <DateHeader isOdd={weekIndex % 2 !== 0}>{week.week}</DateHeader>
                    {week.games.length > 0 ? (
                        week.games.map((game, gameIndex) => (
                            <MatchupRow key={game.id} index={weekIndex * week.games.length + gameIndex}>
                                <TeamName>{game.team1}</TeamName>
                                <Versus>vs</Versus>
                                <TeamName>{game.team2}</TeamName>
                                <GameTime>{game.time}</GameTime>
                                <TeamScore>
                                    <LeftScore>{game.score1}</LeftScore>
                                    <RightScore>{game.score2}</RightScore>
                                </TeamScore>
                            </MatchupRow>
                        ))
                    ) : (
                        <MatchupRow index={weekIndex}>
                            <GameTime>{week.note}</GameTime>
                        </MatchupRow>
                    )}
        </ScheduleContainer>
            ))}
        

        
        </>
    );

    
};

export default Schedule;




