import React from "react";
import { useEffect, useState } from "react";
import { useParams, useNavigate  } from "react-router-dom";
import jsonData from '../Basketball/summer24.json';
import { Section, SportLandingTitle, SportContainer } from "../../../App.styled";

import Week from "./Week"

function Team() {

    const [schedule, setScheduleData] = useState([]);
    let { teamId } = useParams();
    let navigate = useNavigate();
    useEffect(() => {
        setScheduleData(jsonData[0]?.Schedule || []);
    }, []);

    useEffect(() => {
        if (teamId === undefined || teamId === 'undefined') {
            navigate('/Basketball/Schedule', { replace: true });
        }
    }, [teamId, navigate]);

console.log( teamId)



    console.log(schedule.map(week => {
        return week
    }))

    return (

        <Section>
            <SportContainer>

            
            <SportLandingTitle>{teamId} Schedule</SportLandingTitle>
            {schedule.map((week, weekIndex) => (
                <Week week={week} weekIndex={weekIndex} teamId={teamId}/>
            ))}
            </SportContainer>
        </Section>
    );
}

export default Team;



