import styled, {keyframes} from 'styled-components';
//Color Key:
import { colorMap } from '../../../App.styled';
// Logo blue: #34b3d1
// Logo Mustard Yellow: #ffd369
// background grey: #f6f6f6
// dark blue/black #333
// dark blue #282c34
// score grey: #edeef0

const pulseColor = keyframes`
  0% {
    transform: scale(1);
    color: ${colorMap.light.text};

  }
  50% {
    transform: scale(1.2);
    background-color: ${colorMap.light.background};
;
  }
  100% {
    transform: scale(1);
    color: ${colorMap.light.text};
  }
`;

export const StyledBurger = styled.button`
  position: sticky;
  top: 5%;
  left: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 3em;
  height: 3em;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1000;

  &:focus {
    outline: none;
  }

  div {
    width: 2.5rem;
    height: 0.4rem;
    background-color: ${colorMap.light.text};
    background: ${({ isOpen }) => isOpen ? colorMap.light.accent : colorMap.light.text};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    animation: ${pulseColor} 2s ease-in-out 2;


  }

  @media (min-width: 769px) {
    display: none; // Hide burger menu on larger screens
  }



`;
