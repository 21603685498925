import { MenuBar, MenuOptionsContainer, MenuItem, DropdownContent, MenuLogo } from './Menu.styled';
import logowhite from "../../logo/logowhite.svg"
import React, { useState } from 'react';

const Menu = () => {
    const [isOpen] = useState(false);
    return (
        <>
    <MenuBar>
    <MenuLogo src={logowhite}/>

      <MenuOptionsContainer open={isOpen}>

        <MenuItem to="/">Home
        </MenuItem>
        <MenuItem to="/About">About
        </MenuItem>
        <MenuItem>  Sports
          <DropdownContent className="dropdown-content">
            <MenuItem to="/Basketball">Basketball</MenuItem>
            <MenuItem to="/FlagFootball">Flag Football</MenuItem>
            <MenuItem to="/Tournaments">Tournaments</MenuItem>
            <MenuItem to="/Kickball">Kickball</MenuItem>
          </DropdownContent>
        </MenuItem>
        <MenuItem to="/youthCamps">Youth Camp</MenuItem>
      </MenuOptionsContainer>
    </MenuBar>                    
        </>
    );
};

export default Menu;


