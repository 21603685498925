import styled from 'styled-components';
import { colorMap } from '../../../App.styled';

export const StoriesContainer = styled.div`
  display: flex;
  gap: 20px; 
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  margin-bottom: 10vh;
  @media(max-width: 648px) {
    margin-bottom: 5vh;

  }
  
`;
export const TextContainer = styled.div`
display: flex;
width: 549px;
height: auto;
flex-direction: column;
justify-content: space-between;
align-items: center;
@media(max-width: 648px) {
    width: 400px;
}

`

export const StoryCard = styled.div`


    display: flex;
    padding: 12px 18px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    width: 549px;
    height: 400px;
    align-items: center;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.70);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 5px 0px rgba(0, 0, 0, 0.30);
        @media(max-width: 1150px) {
            padding: 5px;
        }
        @media(max-width: 648px) {
            width: 400px;
        }
        @media(max-width: 500px) {
            width: 375px;
        }
        
`;

export const ImageContainer = styled.div`
display: flex;
height: 100%;
width: 100%;
justify-content: center;
align-items: center;
@media(max-width: 968px) {
    display: none;
}
`

export const SVGImage = styled.img`
  flex: 1;
  height: auto;
  width: 100%;
  max-height: 250px; 
  object-fit: contain; 
  @media(max-width: 1150px) {
    max-height: 200px; 
}
  @media(max-width: 968px) {
    display: none;
  }
`;


export const StoryTitle = styled.h2`
color: ${colorMap.light.accent};
border-bottom: 2px solid ${colorMap.light.primary};
text-align: center;
text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    -webkit-text-stroke-width: 0px;
-webkit-text-stroke-color: ${colorMap.light.text};
font-size: 3em;
margin: .5em 0;
font-style: normal;
font-weight: 800;
line-height: normal;
@media(max-width: 1150px) {
    font-size: 2em;
    font-weight: 600;

  }
  @media(max-width: 648px) {
    width: 400px;

}
`

export const InnerContainer = styled.div`
    display: flex;
    width: 529.053px;
    padding: 66px 91px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    @media(max-width: 1150px) {
         width: 400px;
        padding: 44px 70px;
       
      }
      @media(max-width: 648px) {
        
       padding: 16px 36px;
      
     }
     @media(max-width: 530px) {
        
        padding: 8px 16px;
       
      }
`

export const InnerAboutText = styled.p`
color: ${colorMap.dark.text};

text-align: center;
padding: 66px 91px;

font-family: Nokora;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
@media(max-width: 1150px) {
    font-size: 1em;

 }
 @media(max-width: 648px) {
    padding: 32px 64px

 }
`

