import React from "react";
import { Section, SportContainer, InnerContentContainer, TitleContainer, SportLandingTitle, SportName, RegisterButton, SpecialText,   MainText, MainTextContainer, AllTextContainer, RegisterButtonContainer, SubtextContainer, Subtext } from "../../../App.styled";

import { Link } from 'react-router-dom';

function FlagFootball() {



    return (

        <Section>
        <SportContainer>
            <InnerContentContainer >

                <TitleContainer>
                    <SportLandingTitle>Flag Football League, 2025
                    </SportLandingTitle>
                    <SportName>Flag Football</SportName>
                </TitleContainer>

                <RegisterButtonContainer>
                    <RegisterButton target="_blank" rel="noopener noreferrer" as={Link} to={'https://docs.google.com/forms/d/e/1FAIpQLScNXTr7WcbFezGIjiuoE_ple0Udulwi8Nrpk5kj5CZypyU11g/viewform'}>Team Registration</RegisterButton>
                </RegisterButtonContainer>

                <AllTextContainer>
                    <MainTextContainer>

                    
                        <MainText>
                        Interactive Schedules & Scores: Stay up-to-date with the latest game schedules and scores.
                        <br></br>
                        Player Spotlights: Get to know your favorite players with our in-depth profiles and stats. 
                        <br></br>
                        Enhanced Multimedia Content: Look forward to engaging photos, videos, and other multimedia 
                        content showcasing the thrilling moments of flag football.
                        <br></br>
                        Community Features: We're building interactive elements to bring our flag football community closer together.
                            <br></br>
                           <p style={{textDecoration: 'underline', fontWeight: 'bold'}}> Currently under constructrion</p> 
                            <br></br>
                            We understand the inconvenience caused during this update and sincerely appreciate your patience.
                            Our goal is to create a vibrant and user-friendly platform for all flag football enthusiasts.
                            Stay Tuned for Updates!
                        </MainText>

                    </MainTextContainer>
                    <SubtextContainer >
                            <Subtext >
                            For any inquiries or assistance, feel free to reach out to us via Instgram, or Email at the bottom of every page. 
                            We're here to ensure you have the best experience with Ohana Sports. 
                            Mahalo for Supporting <SpecialText style={{color: '#34B3D1'}}>Ohana Sports</SpecialText>!
                            </Subtext>
                            <Subtext>
                            We're committed to providing a comprehensive and engaging experience for all basketball fans.
                             Keep this page bookmarked for the latest schedules, scores, and updates.
                            </Subtext>
                        </SubtextContainer>
                </AllTextContainer>
            </InnerContentContainer>
        </SportContainer>
    </Section>

    );
}

export default FlagFootball;

