import React from 'react';

import {
    FooterContainer, FooterText, FooterSocialIcons, CallToActionText, Copyright, OhanaCopyright, StyledInstagram, StyledEmail, SocialIconsContainer
} from './Footer.styled'; 

function Footer() {
    const year = new Date().getFullYear(); 
   


    return (
        <FooterContainer>
            <FooterText>
                <Copyright> 
                    &copy;{year} 
                </Copyright>

                <OhanaCopyright>
                Copyright OhanaSports {year}  
                </OhanaCopyright>
                <CallToActionText>
                To register or any questions, please email us or send a message on Instagram.
                </CallToActionText>
                
                <SocialIconsContainer>
                    <FooterSocialIcons
                        href="https://www.instagram.com/_ohanasports_/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                            <StyledInstagram />
                    
                    </FooterSocialIcons>
                        <FooterSocialIcons
                            href="mailto:ohanasportsllc@gmail.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                        
                            <StyledEmail />

                    </FooterSocialIcons>

                </SocialIconsContainer>
            </FooterText>
            
        </FooterContainer>

    );
}

export default Footer;



