import styled from 'styled-components';
import { colorMap } from '../../../App.styled';
import { Link } from 'react-router-dom';
// import line from "../../logo/textbarVector.svg"


export const List = styled.ul`
  list-style: inside circle;
  width: 70%;
  color: ${colorMap.light.secondary};
  flex-direction: column;
  margin: auto;
  text-align: left;
  padding-left: 20px;
`;

export const ListItem = styled.li`
font-size: 1rem;
  margin-bottom: 10px;
  margin-top:1rem;
`;

export const Highlight = styled.span`
  color: ${colorMap.light.secondary};
  font-weight: bold;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InformationButton = styled.button`
cursor: pointer;

  width: 200px;
  height: 45px;
  border-radius: 10px;
  background-color: ${colorMap.light.accent};
  

  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  transition: transform 0.1s ease; // Smooth transition for the transform property

  &:hover {
    transform: translate(0px, -2px);
    box-shadow: 2px 3px 6px 0px rgba(0, 0, 0, 0.25);

  }
  
  &:active {
    transform: translate(0px, 1px);
  }
  
  @media(max-width: 912px) {
     padding: 5px;
  max-width: 150px;
  height: 42px;
  font-size: .8em;
  }
`;

export const LinkText = styled(Link)`
  color: ${colorMap.light.text};
text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.10);
font-size: 16px;
font-style: normal;
font-weight: 700;
text-decoration: none;
line-height: normal;
  @media(max-width: 912px) {
   
  font-size: 1em;
  }
`



export const ExternalInfoButton = styled.a`
cursor: pointer;
color: var(--Black, #000);
text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.10);
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
  width: 200px;
  height: 45px;
  border-radius: 10px;
  background-color: ${colorMap.light.accent};
  color: ${colorMap.light.text};
  

  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  transition: transform 0.1s ease; // Smooth transition for the transform property

  &:hover {
    transform: translate(0px, -2px);
    box-shadow: 2px 3px 6px 0px rgba(0, 0, 0, 0.25);

  }
  
  &:active {
    transform: translate(0px, 1px);
  }
`;