import styled from 'styled-components';
import { AiOutlineInstagram, AiOutlineMail } from 'react-icons/ai';
import { colorMap } from '../../../App.styled';

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colorMap.light.text}; 
  color: ${colorMap.light.accent};
  height: auto;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
`;
export const AddressLink = styled.a`
  color: ${colorMap.light.primary}; 
  text-decoration: none; 

  &:hover {
    text-decoration: underline; 
    color: ${colorMap.light.accent}; 
  }
`;
export const ContactText = styled.p`
  font-size: 16px;
  color: ${colorMap.light.background}; 
  
`;
export const FooterText = styled.div`
  font-size: 18px; 
  display: flex;
  justify-content: center;
  color: ${colorMap.light.accent}; 
`;

export const FooterSocialIcons = styled.a`
  margin: 5px 10px; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white; 
  transition: transform 0.3s ease, color 0.3s linear;

  &:hover {
    transform: scale(1.1);
    color: ${colorMap.light.accent}; 
  }
    @media(max-width: 812px){
  width: 30px;
  height: 30px;

}
`;

export const Copyright = styled.p`
font-size: 18px;
margin-right: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-left: 12px;

@media(max-width: 812px){
font-size: 14px;

}
`

export const OhanaCopyright = styled.p`
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
color: ${colorMap.light.primary}; 
@media(max-width: 812px){
  display: none;
  margin-left: 8px;
}


`
export const CallToActionText = styled.p`
font-size: 18px;
color: ${colorMap.light.background}; 
font-style: normal;
font-weight: 700;
line-height: normal;
margin-left: 12px;
margin-right: 10px;
@media(max-width: 812px){
font-size: 14px;
font-weight: 500;
margin-left: 8px;

}
@media(max-width: 600px){
font-size: 12px;


}
`

export const StyledInstagram = styled(AiOutlineInstagram)`
height: 75%;
width: 75%;
color: ${colorMap.light.primary}; 

transition: transform 0.3s ease, color 0.3s linear;
&:hover {
    transform: scale(1.1);
    color: red; 
  }
`;

export const StyledEmail = styled(AiOutlineMail)`
height: 75%;
width: 75%;
color: ${colorMap.light.accent}; 

transition: transform 0.3s ease, color 0.3s linear;
&:hover {
    transform: scale(1.1);
    color: ${colorMap.light.accent}; 
  }
`;

export const SocialIconsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

`;

